export const GameName = 'Бобёрдл';
export const GameURL = 'https://words.bober.ru';

export const Alerts = {
	word: 'Загаданное слово: ',
	notaword: 'Слова нет в списке',
	share: 'Текст скопирован в буфер обмена',
	win: 'Правильно',
};

export const Tutorial = {
	sixTries: 'Угадайте слово с 6 попыток',
	everyDay: 'Каждый день загадывается новое слово, одинаковое для всех.',
	gameDescription:
		'Новая игра-головоломка. Участникам необходимо угадать слово за 6 попыток. Кнопка выбора уровня сложности находится в верхнем левом углу (5 или 6 букв).',
	squaresDescription: 'После каждой попытки буквы отмечаются разным цветом:',
	greenSquare: 'буква на своём месте',
	orangeSquare: 'буква не на своём месте',
	graySquare: 'буквы в слове нет',
};

export const Buttons = {
	again: 'Играть ещё',
	share: 'Поделиться',
};

export const Help = {};
