import React, { useCallback, useEffect, useState } from 'react';
import classNames from './css/classNames';
import Keyboard, { KeyStates } from './components/Keyboard';
import { CellState, changeCellStates } from './components/Cell';
import Row from './components/Row';
import NavBar from './components/NavBar';
import Alert, { AlertType } from './components/Alert';
import { GameName, GameURL, Tutorial } from './res/Strings';
import ym from 'react-yandex-metrika';
import ResultsModal from './components/ResultsModal';
import HelpModal from './components/HelpModal';

const ROWS = 6;
export type WordSize = 0 | 1;
const WORDS: [Array<string>, Array<string>] = require('./res/words.json');

const generateWord = (size: WordSize): string => {
	const randomIndex = Math.floor(0.5 * Math.random() * WORDS[size].length);
	return WORDS[size][randomIndex].toUpperCase();
};

const initDate = () => {
	const d = new Date();
	return d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear();
};

const addPlay = (size: WordSize) => {
	const loadedPlays = localStorage.getItem('plays');
	let plays: [number, number] = [0, 0];
	if (loadedPlays) {
		plays = JSON.parse(loadedPlays);
	}
	plays[size]++;
	localStorage.setItem('plays', JSON.stringify(plays));
};

const loadWins = (): [Array<number>, Array<number>] => {
	const loadedWins = localStorage.getItem('wins');
	if (loadedWins) {
		return JSON.parse(loadedWins);
	}
	return [
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
	];
};

const addWin = (size: WordSize, tries: number) => {
	const wins = loadWins();
	wins[size][tries - 1]++;
	localStorage.setItem('wins', JSON.stringify(wins));
};

const createGrid = (cellsMatrix: Array<Array<CellState>>) =>
	cellsMatrix
		.filter(
			cellsArray => cellsArray.reduce((acc, curr) => acc + (curr === 'hidden' || curr === 'empty' ? 1 : 0), 0) !== cellsArray.length,
		)
		.map(cellsArray => cellsArray.map(cell => (cell === 'correct' ? '🟩' : cell.includes('present') ? '🟨' : '⬜')).join(''))
		.join('\n');

function App() {
	const [date] = useState<string>(initDate);
	const [words, setWords] = useState<[string, string]>(['', '']);
	const [guesses, setGuesses] = useState<[Array<string>, Array<string>]>([[], []]);
	const [word, setWord] = useState<string>('');
	const [wordSize, setWordSize] = useState<WordSize>(JSON.parse(localStorage.getItem('six') ?? 'true') ? 1 : 0);
	const [input, setInput] = useState<string>('');
	const [cellStates, setCellStates] = useState<Array<Array<CellState>>>([]);
	const [keyStates, setKeyStates] = useState<KeyStates>({ absent: [], present: [], correct: [] });
	const [alert, setAlert] = useState<AlertType>();

	const [gameOver, setGameOver] = useState<[number, number]>([0, 0]); // 0 in progress, 1-6 win, -1 fail
	const [resultsOpened, setResultsOpened] = useState(false);
	const [helpOpened, setHelpOpened] = useState(false);

	// Load from Local Storage
	useEffect(() => {
		const loadedDate: string | null = localStorage.getItem('date');
		const loadedWords: [string, string] | null = JSON.parse(localStorage.getItem('words') ?? 'null');
		const loadedGuesses: [Array<string>, Array<string>] | null = JSON.parse(localStorage.getItem('guesses') ?? 'null');
		if (loadedDate === date && loadedWords && loadedWords.length === 2) {
			setWords(loadedWords);
			if (Array.isArray(loadedGuesses) && loadedGuesses.length === 2) {
				setGuesses(loadedGuesses);
				setGameOver(() => {
					const gOver: [number, number] = [0, 0];
					[0, 1].forEach(i => {
						const guessesNum = loadedGuesses[i].length;
						if (guessesNum > 0 && loadedGuesses[i][guessesNum - 1] === loadedWords[i]) {
							gOver[i] = guessesNum;
						}
					});
					return gOver;
				});
			}
		} else {
			const fetchWord = async () => {
				const response = await fetch('https://words.bober.ru/api.php');
				if (response.status === 200) {
					const json = await response.json();
					const jsonWords = json.word.map((w: string) => w.toUpperCase());
					localStorage.setItem('date', date);
					localStorage.setItem('words', JSON.stringify(jsonWords));
					localStorage.setItem('guesses', '[[],[]]');
					setWords(jsonWords);
				} else {
					throw new Error();
				}
			};
			fetchWord().catch(() => {
				const generatedWords: [string, string] = [generateWord(0), generateWord(1)];
				localStorage.setItem('date', date);
				localStorage.setItem('words', JSON.stringify(generatedWords));
				localStorage.setItem('guesses', '[[],[]]');
				setWords(generatedWords);
			});
		}
	}, [date]);

	useEffect(() => {
		const newWord = words[wordSize];
		if (newWord.length === wordSize + 5) {
			setWord(newWord);
			localStorage.setItem('six', wordSize > 0 ? 'true' : 'false');
			// console.log('Word:', newWord);
		}
	}, [wordSize, words]);

	useEffect(() => {
		const wSize = word.length - 5;
		if (wSize >= 0) {
			const cells: Array<Array<CellState>> = [];
			const keys: KeyStates = { absent: [], present: [], correct: [] };
			for (let i = 0; i < ROWS; i++) {
				cells[i] = [];
				for (let j = 0; j < word.length; j++) {
					if (i > guesses[wSize].length) {
						cells[i][j] = 'hidden';
					} else if (i === guesses[wSize].length) {
						cells[i][j] = 'empty';
					} else {
						const guess = guesses[wSize][i];
						const gchar = guess.charAt(j);
						if (gchar === word.charAt(j)) {
							cells[i][j] = 'correct';
							keys.correct.push(gchar);
						} else {
							const count = guess
								.substring(0, j + 1)
								.split('')
								.reduce((acc, curr) => (curr === gchar ? acc + 1 : acc), 0);
							const presence = word.includes(gchar)
								? word
										.split('')
										.flatMap((wchar, k) =>
											wchar === gchar && wchar !== guess.charAt(k) ? (k < j ? 'left' : 'right') : [],
										)
								: [];
							if (presence.length > 0 && count <= presence.length) {
								cells[i][j] =
									// word.length === 5 ? 'present' :
									presence.includes('left') && presence.includes('right')
										? 'presentBoth'
										: presence.includes('left')
										? 'presentLeft'
										: 'presentRight';
								keys.present.push(gchar);
							} else {
								cells[i][j] = 'absent';
								keys.absent.push(gchar);
							}
						}
					}
				}
			}
			setCellStates(cells);
			setKeyStates(keys);
			// if (guesses[wSize][guesses[wSize].length - 1] === word) {
			// 	setGameOver(go => (wSize === 0 ? [guesses[wSize].length, go[1]] : [go[0], guesses[wSize].length]));
			// } else if (guesses[wSize].length === ROWS) {
			// 	setGameOver(go => (wSize === 0 ? [-1, go[1]] : [go[0], -1]));
			// }
		}
	}, [guesses, word]);

	useEffect(() => {
		if (alert === 'win') {
			const timeout = setTimeout(() => {
				setAlert(undefined);
				setResultsOpened(true);
			}, 2000);
			return () => clearTimeout(timeout);
		} else if (alert !== undefined && alert !== 'word') {
			const timeout = setTimeout(() => setAlert(undefined), 1000);
			return () => clearTimeout(timeout);
		}
	}, [alert]);

	const onSwitchSize = useCallback(() => {
		setWordSize(size => (size > 0 ? 0 : 1));
		setInput('');
		setAlert(undefined);
		setCellStates([]);
		setKeyStates({ absent: [], present: [], correct: [] });
	}, []);

	const onSwitchResults = useCallback(() => {
		setResultsOpened(opened => !opened);
	}, []);

	const onSwitchHelp = useCallback(() => {
		setHelpOpened(opened => !opened);
	}, []);

	const onChar = useCallback(
		(char: string) => {
			if (gameOver[wordSize] === 0 && input.length < wordSize + 5) {
				setCellStates(cellStates =>
					changeCellStates({ cellStates, r: guesses[wordSize].length, c: input.length, value: 'entered' }),
				);
				setInput(input + char);
			}
		},
		[input, wordSize, guesses, gameOver],
	);

	const onDelete = useCallback(() => {
		if (gameOver[wordSize] === 0 && input.length > 0) {
			setCellStates(cellStates =>
				changeCellStates({ cellStates, r: guesses[wordSize].length, c: input.length - 1, value: 'deleted' }),
			);
			setInput(input.substring(0, input.length - 1));
		} else if (gameOver[wordSize] !== 0) {
			let clear = window.confirm('Очистить кэш?');
			if (clear) {
				// localStorage.setItem('date', '');
				localStorage.clear();
			}
		}
	}, [input, guesses, gameOver, wordSize]);

	const onEnter = useCallback(() => {
		if (gameOver[wordSize] === 0 && input.length === wordSize + 5) {
			if (WORDS[wordSize].includes(input.toLowerCase())) {
				const tries = guesses[wordSize].length + 1;
				if (guesses[wordSize].length === 0) {
					ym('hit', `/start${wordSize + 5}`);
					addPlay(wordSize);
				}
				if (input === word) {
					console.log('win');
					ym('hit', `/win${wordSize + 5}`, { tries });
					addWin(wordSize, tries);
					setGameOver(go => (wordSize === 0 ? [tries, go[1]] : [go[0], tries]));
					setAlert('win');
				} else if (tries === ROWS) {
					setGameOver(go => (wordSize === 0 ? [-1, go[1]] : [go[0], -1]));
					setAlert('word');
				}
				const newGuesses: [Array<string>, Array<string>] =
					wordSize === 0 ? [[...guesses[0], input], guesses[1]] : [guesses[0], [...guesses[1], input]];
				setGuesses(newGuesses);
				localStorage.setItem('guesses', JSON.stringify(newGuesses));
				setInput('');
			} else {
				setAlert('notaword');
			}
		}
	}, [input, wordSize, gameOver, guesses, word]);

	const onShare = useCallback(() => {
		const grid = createGrid(cellStates);
		const shareText = `${GameName} ${wordSize + 5} - ${date}\n${grid}\n${GameURL}`;
		if (navigator.share && (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))) {
			navigator.share({ text: shareText }).then();
		} else {
			navigator.clipboard.writeText(shareText).then();
			setAlert('share');
		}
	}, [cellStates, wordSize, date]);

	const onPlayAgain = useCallback(() => {
		setResultsOpened(false);
		onSwitchSize();
	}, [onSwitchSize]);

	return (
		<div className="absolute inset-0 flex select-none">
			<div className="flex flex-1 flex-col bg-boberGray">
				{/* NavBar */}
				<div className="flex h-14 sm:h-16">
					<NavBar {...{ wordSize, onSwitchSize, onSwitchResults, onSwitchHelp }} />
				</div>
				{/* Rows */}
				<div className="relative flex grow items-center justify-center">
					<div
						className={classNames(
							wordSize === 0 ? 'max-h-116 max-w-sm' : 'max-h-112 max-w-md',
							'flex h-full w-full flex-col p-2',
						)}>
						{cellStates.map((states, i) => (
							<Row
								key={'row' + i}
								i={i}
								rows={guesses[wordSize].length + (gameOver[wordSize] !== 0 ? 0 : 1)}
								states={states}
								chars={
									i < guesses[wordSize].length
										? guesses[wordSize][i]
										: i === guesses[wordSize].length
										? input.padEnd(wordSize + 5)
										: ''.padStart(wordSize + 5)
								}
								wiggle={alert === 'notaword' && i === guesses[wordSize].length}
								last={i === guesses[wordSize].length - 1}
								win={alert === 'win'}
							/>
						))}
					</div>
					<div
						className={classNames(
							guesses[wordSize].length > 0 ? 'scale-75 opacity-0' : null,
							'absolute top-0 flex h-1/2 w-full flex-col items-center justify-center transition-all duration-500',
						)}>
						<span className="rounded-lg text-center text-lg font-medium text-gray-400 xs:text-xl sm:text-2xl">
							{Tutorial.sixTries}
						</span>
						<br />
						<span className="rounded-lg px-2 text-center text-lg text-gray-400 xs:text-lg sm:text-xl">{Tutorial.everyDay}</span>
					</div>
					<div
						className={classNames(
							gameOver[wordSize] !== 0 || guesses[wordSize].length < 1 || guesses[wordSize].length > 2
								? 'scale-75 opacity-0'
								: null,
							'absolute bottom-0 h-1/2 transition-all duration-500',
						)}>
						<div className="flex h-full w-full flex-col justify-between overflow-hidden text-lg font-medium text-gray-400 xs:text-xl sm:text-2xl">
							<br />
							<div className="flex flex-col">
								<div className="flex items-center">
									<div className="mr-2 h-4 w-4 animate-flip rounded bg-boberGreen xs:h-6 xs:w-6"></div>
									<span>{Tutorial.greenSquare}</span>
								</div>
								<div className="flex items-center">
									<div className="mr-2 h-4 w-4 animate-flip rounded bg-boberOrange xs:h-6 xs:w-6"></div>
									<span>{Tutorial.orangeSquare}</span>
								</div>
								<div className="flex items-center">
									<div className="mr-2 h-4 w-4 animate-flip rounded bg-gray-400 xs:h-6 xs:w-6"></div>
									<span>{Tutorial.graySquare}</span>
								</div>
							</div>
							<br />
						</div>
					</div>
				</div>
				{/* Keyboard */}
				<div className="flex h-36 justify-center sm:h-48 ">
					<Keyboard {...{ keyStates, onChar, onEnter, onDelete }} onTab={onSwitchSize} onHome={onSwitchResults} />
				</div>
			</div>
			{/* Alert */}
			<div className="absolute top-24 flex h-10 w-full items-center justify-center ">
				<Alert alert={alert} word={word} />
			</div>
			{/* Results Modal Window */}
			{resultsOpened && (
				<ResultsModal
					date={date}
					wins={loadWins()}
					onClose={onSwitchResults}
					onShare={gameOver[wordSize] !== 0 ? onShare : undefined}
					onPlayAgain={gameOver[wordSize] !== 0 && gameOver[wordSize === 0 ? 1 : 0] === 0 ? onPlayAgain : undefined}
					gameOver={gameOver}
					grid={cellStates}
				/>
			)}
			{helpOpened && <HelpModal onClose={onSwitchHelp} />}
		</div>
	);
}

export default App;
