import React, { useEffect, useState } from 'react';
import classNames from '../css/classNames';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';

export type CellState =
	| 'hidden'
	| 'empty'
	| 'entered'
	| 'deleted'
	| 'absent'
	| 'present'
	| 'correct'
	| 'presentRight'
	| 'presentLeft'
	| 'presentBoth';

interface CellInterface {
	i: number;
	state: CellState;
	char: string;
	animated: boolean;
	win: boolean;
}

export function changeCellStates({
	cellStates,
	r,
	c,
	value,
}: {
	cellStates: Array<Array<CellState>>;
	r: number;
	c: number;
	value: CellState;
}): Array<Array<CellState>> {
	return cellStates.map((row, i) => {
		return i !== r
			? row
			: row.map((cell, j) => {
					return j !== c ? cell : value;
			  });
	});
}

const Cell = ({ i, state, char, animated, win }: CellInterface) => {
	const [fillState, setFillState] = useState<
		'absent' | 'present' | 'correct' | 'presentLeft' | 'presentRight' | 'presentBoth' | undefined
	>(undefined);
	useEffect(() => {
		if (
			state === 'absent' ||
			state === 'present' ||
			state === 'correct' ||
			state === 'presentLeft' ||
			state === 'presentRight' ||
			state === 'presentBoth'
		) {
			if (animated) {
				const timeout = setTimeout(() => {
					setFillState(state);
				}, i * 150);
				return () => clearTimeout(timeout);
			} else {
				setFillState(state);
			}
		}
	}, [state, i]);
	return (
		<div className="relative flex flex-1 p-0.5">
			<div
				className={classNames(
					state === 'entered' ? 'animate-wink' : null,
					state === 'deleted' ? 'animate-jump' : null,
					fillState === 'absent'
						? 'bg-gray-400'
						: fillState?.includes('present')
						? 'bg-boberOrange'
						: fillState === 'correct'
						? 'bg-boberGreen'
						: 'bg-white',
					fillState ? (animated ? 'animate-flip text-white transition-colors delay-100' : 'text-white') : 'text-gray-800 ',
					'flex h-full w-full items-center justify-center rounded-lg text-center font-sans text-2xl font-bold shadow-sm xs:text-3xl sm:text-4xl md:shadow-md',
				)}>
				{char.toUpperCase()}
			</div>
			<div
				className={classNames(
					fillState && fillState.includes('present')
						? 'scale-100 opacity-50 transition-all delay-700 duration-500'
						: 'scale-50 opacity-0',
					fillState === 'presentLeft' ? 'justify-start' : fillState === 'presentRight' ? 'justify-end' : 'justify-between',
					'absolute inset-0 mt-1 flex items-center text-white',
				)}>
				{(fillState === 'presentLeft' || fillState === 'presentBoth') && (
					<BsChevronCompactLeft className="-m-1 text-2xl xs:text-3xl sm:text-4xl" />
				)}
				{(fillState === 'presentRight' || fillState === 'presentBoth') && (
					<BsChevronCompactRight className="-m-1 text-xl xs:text-3xl sm:text-4xl" />
				)}
			</div>
		</div>
	);
};

export default Cell;
