import { IoCloseCircle } from 'react-icons/io5';
import React from 'react';
import { GameName, Tutorial } from '../res/Strings';

const HelpModal = ({ onClose }: { onClose: () => void }) => (
	<div className="absolute inset-0 flex items-center justify-center">
		<div className="absolute inset-0 bg-black opacity-10" onClick={onClose}></div>
		<div className="relative flex w-80 animate-appear flex-col items-center rounded-xl border-4 border-boberGreen bg-white p-4 font-sans font-normal text-gray-800 shadow-xl ring-4 ring-white ease-out sm:w-116">
			<div className="absolute top-0 right-0 cursor-pointer p-1 text-3xl text-boberOrange" onClick={onClose}>
				<IoCloseCircle />
			</div>
			<h1 className="mb-2 p-2 text-2xl font-semibold text-gray-700 sm:text-3xl">{GameName}</h1>
			<p className="text-md indent-6 font-sans text-gray-400  sm:px-4 sm:text-xl">{Tutorial.gameDescription}</p>
			<p className="text-md indent-6 font-sans text-gray-400  sm:px-4 sm:text-xl">{Tutorial.squaresDescription}</p>
			<div className="text-md my-2 flex flex-col text-gray-400 sm:text-xl">
				<div className="flex items-center p-1">
					<div className="mr-2 h-6 w-6 rounded bg-boberGreen"></div>
					<span>{Tutorial.greenSquare}</span>
				</div>
				<div className="flex items-center p-1">
					<div className="mr-2 h-6 w-6 rounded bg-boberOrange"></div>
					<span>{Tutorial.orangeSquare}</span>
				</div>
				<div className="flex items-center p-1">
					<div className="mr-2 h-6 w-6 rounded bg-gray-400"></div>
					<span>{Tutorial.graySquare}</span>
				</div>
			</div>
		</div>
	</div>
);

export default HelpModal;
