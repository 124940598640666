import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { YMInitializer } from 'react-yandex-metrika';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// <React.StrictMode>
	<div>
		<YMInitializer accounts={[49337350]} options={{ webvisor: true }} />
		<App />
	</div>,
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
