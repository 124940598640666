import React, { useEffect, useState } from 'react';
import classNames from '../css/classNames';
import { Alerts } from '../res/Strings';

export type AlertType = 'word' | 'notaword' | 'share' | 'win' | undefined;
const ALERTS = {
	word: Alerts.word,
	notaword: Alerts.notaword,
	share: Alerts.share,
	win: Alerts.win,
};
const COLORS = {
	word: 'bg-boberGreen',
	notaword: 'bg-boberPink',
	share: 'bg-boberOrange',
	win: 'bg-boberGreen',
};

const Alert = ({ alert, word }: { alert: AlertType; word: string }) => {
	const [text, setText] = useState('');
	const [color, setColor] = useState('bg-gray-400');
	useEffect(() => {
		if (alert !== undefined) {
			setText(ALERTS[alert] + (alert === 'word' ? word : ''));
			setColor(COLORS[alert]);
		}
	}, [alert, word]);

	return (
		<div
			className={classNames(
				alert !== undefined ? 'scale-100' : 'scale-75 opacity-0',
				color,
				'rounded-md bg-boberPink p-2 text-center font-sans text-lg font-semibold text-white shadow-md transition-all sm:text-xl',
			)}>
			{text}
		</div>
	);
};

export default Alert;
