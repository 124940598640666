import React from 'react';
import { FaShareSquare, FaPlayCircle } from 'react-icons/fa';
import { Buttons } from '../res/Strings';
import classNames from '../css/classNames';
import { CellState } from './Cell';
import { IoCloseCircle } from 'react-icons/io5';

const ResultsModal = ({
	date,
	wins,
	gameOver,
	grid,
	onClose,
	onShare,
	onPlayAgain,
}: {
	date: string;
	wins: [number[], number[]];
	gameOver: [number, number];
	grid: Array<Array<CellState>>;
	onClose: () => void;
	onShare?: () => void;
	onPlayAgain?: () => void;
}) => {
	const max5 = Math.max(5, ...wins[0]);
	const max6 = Math.max(5, ...wins[1]);
	// const date = new Date();
	// const dateString = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
	// 	.toString()
	// 	.padStart(2, '0')}/${date.getFullYear()}`;
	return (
		<div className="absolute inset-0 flex items-center justify-center">
			<div className="absolute inset-0 bg-black opacity-10" onClick={onClose}></div>
			<div className="relative flex w-80 animate-appear flex-col items-center rounded-xl border-4 border-boberGreen bg-white p-4 font-sans font-semibold text-gray-800 shadow-xl ring-4 ring-white sm:w-116">
				<div className="absolute top-0 right-0 cursor-pointer p-1 text-3xl text-boberOrange" onClick={onClose}>
					<IoCloseCircle />
				</div>
				<h1 className="mb-2 font-sans text-2xl text-gray-700 sm:text-3xl">Статистика</h1>
				<div className="relative flex w-3/4">
					{/*<div className="absolute flex w-full items-center justify-center">Попытки</div>*/}
					<div className="m-0.5 flex flex-1 justify-center font-medium text-gray-400">5 букв</div>
					<div className="m-0.5 flex flex-1 justify-center font-medium text-gray-400">6 букв</div>
				</div>
				<div className="mb-2 flex w-3/4 flex-col">
					{[0, 1, 2, 3, 4, 5].map(i => {
						const win5 = wins[0][i];
						const win6 = wins[1][i];
						return (
							<div className="flex">
								<div className="my-0.5 flex flex-1 justify-end bg-gray-50 text-white">
									<div
										className={classNames(
											win5 / max5 > 0.8
												? 'w-full'
												: win5 / max5 > 0.6
												? 'w-4/5'
												: win5 / max5 > 0.4
												? 'w-3/5'
												: win5 / max5 > 0.2
												? 'w-2/5'
												: win5 / max5 > 0
												? 'w-1/5'
												: 'w-1/10',
											gameOver[0] === i + 1 ? 'bg-boberGreen' : gameOver[0] < 0 ? 'bg-boberOrange' : 'bg-gray-300',
											'flex justify-start rounded px-1',
										)}>
										{win5 > 0 ? win5 : ''}
									</div>
								</div>
								<div className="flex items-center px-1 text-gray-700">{i + 1}</div>
								<div className="my-0.5 flex flex-1 justify-start bg-gray-50 text-white">
									<div
										className={classNames(
											win6 / max6 > 0.8
												? 'w-full'
												: win6 / max6 > 0.6
												? 'w-4/5'
												: win6 / max6 > 0.4
												? 'w-3/5'
												: win6 / max6 > 0.2
												? 'w-2/5'
												: win6 / max6 > 0
												? 'w-1/5'
												: 'w-1/10',
											gameOver[1] === i + 1 ? 'bg-boberGreen' : gameOver[1] < 0 ? 'bg-boberOrange' : 'bg-gray-300',
											'flex justify-end rounded px-1',
										)}>
										{win6 > 0 ? win6 : ''}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				{onShare && <h2 className="mb-1 text-gray-700">{date}</h2>}
				{onShare && (
					<div className="flex flex-col">
						{grid
							.filter(
								cellsArray =>
									cellsArray.reduce((acc, curr) => acc + (curr === 'hidden' || curr === 'empty' ? 1 : 0), 0) !==
									cellsArray.length,
							)
							.map(cellsArray => (
								<div className="flex flex-row">
									{cellsArray.map(cell => (
										<div
											className={classNames(
												cell === 'correct'
													? 'bg-boberGreen'
													: cell.includes('present')
													? 'bg-boberOrange'
													: 'border border-gray-200 bg-gray-100',
												'm-0.5 h-4 w-4 rounded-sm',
											)}></div>
									))}
								</div>
							))}
					</div>
				)}
				{onShare && (
					<button
						className="mt-4 flex items-center space-x-2 rounded-lg bg-boberGreen px-4 py-2 font-sans text-lg font-semibold uppercase text-white"
						onClick={onShare}>
						<FaShareSquare size={24} />
						<span>{Buttons.share}</span>
					</button>
				)}
				{onPlayAgain && (
					<button
						className="mt-2 flex items-center space-x-2 rounded-lg bg-boberOrange px-4 py-2 font-sans text-xl font-semibold uppercase text-white"
						onClick={onPlayAgain}>
						<FaPlayCircle size={24} />
						<span>{Buttons.again}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default ResultsModal;
