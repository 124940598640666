import React from 'react';
import Cell, { CellState } from './Cell';
import classNames from '../css/classNames';

interface RowInterface {
	i: number;
	rows: number;
	states: Array<CellState>;
	chars: string;
	wiggle?: boolean;
	last?: boolean;
	win?: boolean;
}

const Row = ({ i, rows, chars, states, wiggle = false, last = true, win = false }: RowInterface) => {
	return (
		<div
			key={'row' + i}
			className={classNames(
				i === 0
					? rows === 1
						? 'translate-y-250%'
						: rows === 2
						? 'translate-y-150%'
						: rows === 3
						? 'translate-y-50%'
						: null
					: null,
				i === 1 ? (rows < 2 ? 'translate-y-250%' : rows === 2 ? 'translate-y-150%' : rows === 3 ? 'translate-y-50%' : null) : null,
				i === 2 ? (rows <= 2 ? 'translate-y-150%' : rows === 3 ? 'translate-y-50%' : null) : null,
				i === 3 ? (rows <= 3 ? 'translate-y-50%' : null) : null,
				i === 4 ? (rows <= 4 ? 'translate-y-50%' : null) : null,
				i >= rows ? 'opacity-0' : null,
				'flex flex-1 transition delay-1000 duration-500 ease-in-out',
			)}>
			<div className={classNames(wiggle ? 'animate-wiggle' : null, 'flex flex-1')}>
				{states.map((state, j) => (
					<Cell key={'cell' + i + j} i={j} char={chars.charAt(j)} state={state} animated={last} win={win} />
				))}
			</div>
		</div>
	);
};

export default Row;
