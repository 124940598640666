import React from 'react';
import ImageLogo from '../img/logo.svg';
import WordSwitch from './WordSwitch';
import { WordSize } from '../App';
import { IoStatsChart, IoHelpCircle } from 'react-icons/io5';

const NavBar = ({
	wordSize,
	onSwitchSize,
	onSwitchResults,
	onSwitchHelp,
}: {
	wordSize: WordSize;
	onSwitchSize: () => void;
	onSwitchResults: () => void;
	onSwitchHelp: () => void;
}) => (
	<div className="flex flex-1 items-center justify-between bg-white px-2 shadow-md">
		{/* WordSwitch */}
		<div className="flex">
			<div className="mt-2 flex h-14 w-14 items-center justify-center">
				<WordSwitch isOn={wordSize > 0} onSwitch={onSwitchSize} />
			</div>
			<div className="h-14 w-14" />
		</div>
		{/* Logo with link */}
		<a className="flex h-14 w-14 items-center justify-center" href="https://bober.ru/">
			<img src={ImageLogo} alt="logo" className="object-cover" />
		</a>
		<div className="flex">
			{/* ResultsButton */}
			<div className="mt-2 flex h-14 w-14 cursor-pointer items-center justify-center text-gray-300" onClick={onSwitchResults}>
				<IoStatsChart size={28} />
			</div>
			<div className="mt-2 flex h-14 w-14 cursor-pointer items-center justify-center text-gray-300" onClick={onSwitchHelp}>
				<IoHelpCircle size={36} />
			</div>
		</div>
	</div>
);

export default NavBar;
