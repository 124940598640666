import React from 'react';
import classNames from '../css/classNames';

const WordSwitch = ({ isOn, onSwitch }: { isOn: boolean; onSwitch: () => void }) => {
	return (
		<div
			className={classNames(
				isOn ? 'pl-2' : null,
				'flex h-8 w-10 items-center rounded-lg border-2 border-gray-300 bg-gray-300 transition-all',
			)}>
			<div
				className={classNames(
					'hover h-7 w-7 cursor-pointer rounded-lg border-2 border-gray-300 bg-white text-center font-bold text-boberOrange',
				)}
				onClick={onSwitch}>
				{isOn ? '6' : '5'}
			</div>
		</div>
	);
};

export default WordSwitch;
