import React, { useEffect } from 'react';
import classNames from '../css/classNames';
import { FaBackspace, FaLevelDownAlt } from 'react-icons/fa';

const ENTER = 'enter';
const BACKSPACE = 'backspace';

const KEYS = [
	['Й', 'Ц', 'У', 'К', 'Е', 'Н', 'Г', 'Ш', 'Щ', 'З', 'Х', 'Ъ'],
	['Ф', 'Ы', 'В', 'А', 'П', 'Р', 'О', 'Л', 'Д', 'Ж', 'Э'],
	[BACKSPACE, 'Я', 'Ч', 'С', 'М', 'И', 'Т', 'Ь', 'Б', 'Ю', ENTER],
];

const ENG_KEYS: { [key: string]: string } = {
	Q: 'Й',
	W: 'Ц',
	E: 'У',
	R: 'К',
	T: 'Е',
	Y: 'Н',
	U: 'Г',
	I: 'Ш',
	O: 'Щ',
	P: 'З',
	'[': 'Х',
	'{': 'Х',
	']': 'Ъ',
	'}': 'Ъ',
	A: 'Ф',
	S: 'Ы',
	D: 'В',
	F: 'А',
	G: 'П',
	H: 'Р',
	J: 'О',
	K: 'Л',
	L: 'Д',
	';': 'Ж',
	':': 'Ж',
	"'": 'Э',
	'"': 'Э',
	Z: 'Я',
	X: 'Ч',
	C: 'С',
	V: 'М',
	B: 'И',
	N: 'Т',
	M: 'Ь',
	',': 'Б',
	'<': 'Б',
	'.': 'Ю',
	'>': 'Ю',
};

export type KeyStates = {
	absent: Array<string>;
	present: Array<string>;
	correct: Array<string>;
};

interface KeyboardInterface {
	keyStates: KeyStates;
	onChar: (char: string) => void;
	onEnter: () => void;
	onDelete: () => void;
	onTab: () => void;
	onHome: () => void;
}

const Keyboard = ({ keyStates, onChar, onEnter, onDelete, onTab, onHome }: KeyboardInterface) => {
	// Keyboard Listener
	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			const char = e.key.toUpperCase();
			if (e.code === 'Tab') {
				e.preventDefault();
				onTab();
			} else if (e.code === 'Enter') {
				e.preventDefault();
				onEnter();
			} else if (e.code === 'Backspace' || e.code === 'Delete') {
				e.preventDefault();
				onDelete();
			} else if (e.code === 'Home' || e.code === 'End' || e.code === 'PageUp' || e.code === 'PageDown') {
				e.preventDefault();
				onHome();
			} else if (char.length === 1) {
				if (char >= 'А' && char <= 'Я') {
					onChar(char);
				} else if (char in ENG_KEYS) {
					onChar(ENG_KEYS[char]);
				}
			}
		};
		window.addEventListener('keydown', listener);
		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [onEnter, onDelete, onChar, onTab, onHome]);

	const onPress = (char: string) => (char === ENTER ? onEnter() : char === BACKSPACE ? onDelete() : onChar(char));

	return (
		<div className="flex h-full w-full max-w-lg flex-col space-y-0.5 p-1 sm:rounded-t-xl ">
			{KEYS.map((keys, i) => (
				<div key={'keys' + i} className="flex flex-1 justify-center">
					{keys.map(k => (
						<Key
							key={'key' + k}
							char={k}
							onPress={onPress}
							color={
								k === ENTER || k === BACKSPACE
									? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
									: keyStates.correct.includes(k)
									? 'bg-boberGreen text-white hover:opacity-75'
									: keyStates.present.includes(k)
									? 'bg-boberOrange text-white hover:opacity-75'
									: keyStates.absent.includes(k)
									? 'bg-gray-400 text-white hover:bg-gray-300'
									: 'bg-white text-gray-800 hover:bg-gray-300'
							}
						/>
					))}
				</div>
			))}
		</div>
	);
};

const Key = ({ char, color, onPress }: { char: string; color: string; onPress: (char: string) => void }) => (
	<div className={classNames(char === ENTER || char === BACKSPACE ? 'basis-3/24' : 'basis-1/12', 'p-0.5')}>
		<button
			className={classNames(
				color,
				'flex h-full w-full items-center justify-center rounded text-center font-semibold shadow-sm transition-colors duration-700',
			)}
			onClick={() => onPress(char)}>
			{char === BACKSPACE ? (
				<FaBackspace size="24" />
			) : char === ENTER ? (
				<span className="rotate-90">
					<FaLevelDownAlt size="24" />
				</span>
			) : (
				char
			)}
		</button>
	</div>
);

export default Keyboard;
